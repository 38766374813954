$red: #ef1126;

.login-container {
  position: fixed;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
  width: 400px;
  background: #1b2127;
  padding: 30px;
  border-radius: 3px;

  svg {
    display: table;
    margin: auto;
    margin-bottom: 24px;
    height: 30px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1.4;
    text-align: center;
  }

  .reboot {
    color: #F85479;
    font-style: italic;
    font-family: Bitter;
    font-size: 2em;
  }

  .firebaseui-card-header {
    display: none;
  }

  .firebaseui-textfield {
    display: flex;
    align-items: center;

    label {
      margin: 0px;
      margin-right: 12px;
      width: 80px;
    }

    input {
      flex: 1;
      outline: none;
    }
  }

  .firebaseui-form-actions {
    text-align: center;

    button {
      display: inline-block;
      padding: 10px;
      width: auto;
      margin: 10px;
    }
  }

  button {
    background: transparent !important;
    border: 1px solid $red;
    padding: 20px;
    border-radius: 3px;
    display: block;
    margin: auto;
    width: 100%;
    margin-bottom: 10px;

    color: white;

    .firebaseui-idp-text-short {
      display: none;
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {

    }
  }
}